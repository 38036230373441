import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { UIRouterModule } from '@uirouter/angular';
import accountingConfig from './accounting.config';
import { PpfComponent} from './ppf/ppfAccounting.component';
import { AccountingService } from './accounting.service';

@NgModule({
    imports: [
        // Core deps
        UIRouterModule.forChild(accountingConfig),
        BrowserModule,

        // App deps
    ],
    declarations: [
        PpfComponent,
    ],
    providers: [
        AccountingService
    ],
})
export class Accounting {}