import { Component, OnInit, OnDestroy } from '@angular/core';
import { StateService } from '@uirouter/angular';

import { AuthService } from '../authentication.service';
import { Subscription, of } from 'rxjs';
import { concatMap } from 'rxjs/operators';

@Component({
    selector: 'ap-sso',
    templateUrl: './sso.template.html'
})
export class SsoComponent implements OnInit, OnDestroy {
    session: Subscription;

    constructor(
        private $state: StateService,
        private auth: AuthService
    ) {}

    ngOnInit() {
        /**
         * Check for an active (AP) session. If none exists, try to get Okta
         * tokens and create a session.
         */
        const check = this.auth.checkSession()
        .pipe(
            concatMap(active => active ? of(true) : this.auth.getTokens())
        );

        this.session = check
        .subscribe({
            next: active => active ? this.$state.go('dashboard') : this.$state.go('unauthenticated')
        });
    }

    ngOnDestroy() {
        this.session.unsubscribe();
    }
}
