import { NgModule } from '@angular/core';

import { Auth } from './authentication/authentication.module';
import { NavBar } from './navBar/navBar.module';

@NgModule({
    exports: [
        Auth,
        NavBar
    ]
})
export class Core {}
