import { DashboardComponent } from './dashboard.component';

const dashboard = {
    name: 'dashboard',
    url: '/dashboard',
    component: DashboardComponent
};

export default {
    states: [
        dashboard
    ]
};
