import { NgModule } from '@angular/core';
import { UIRouterModule } from '@uirouter/angular';

import dashboardConfig from './dashboard.config';
import { DashboardComponent } from './dashboard.component';

@NgModule({
    imports: [
        UIRouterModule.forChild(dashboardConfig)
    ],
    declarations: [
        DashboardComponent
    ]
})

export class Dashboard {}
