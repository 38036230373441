import { DronesPageComponent } from './drones-page/drones-page.component';

const dronesPage = {
    name: 'drones',
    url: '/drones',
    component: DronesPageComponent
};

export default {
    states: [
        dronesPage
    ]
};
