import { SsoComponent } from './sso/sso.component';
import { UnauthorisedComponent } from './unauthorised/unauthorised.component';
import { UnauthenticatedComponent } from './unauthenticated/unauthenticated.component';

const sso = {
    name: 'sso',
    url: '/',
    component: SsoComponent
};

const unauthorised = {
    name: 'unauthorised',
    url: '/unauthorised',
    component: UnauthorisedComponent
};

const unauthenticated = {
    name: 'unauthenticated',
    url: '/unauthenticated',
    component: UnauthenticatedComponent
};

export default {
    states: [
        sso,
        unauthorised,
        unauthenticated
    ]
};
