import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';

import { map, concatMap } from 'rxjs/operators';

import { AuthService } from '../core/authentication/authentication.service';

@Injectable ()
export class AccountingService {
    constructor(private http: HttpClient, private authService: AuthService) {}

    private httpHeaders() {
        return this.authService.getAuthHeader()
        .pipe(
            map(authHeader => {
                return new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': authHeader
                });
            })
        );
    }

    ppfFlights() {
        return this.httpHeaders()
        .pipe(
            concatMap(headers => {
                return this.http.get<ServerResponse>('/api/v2/flights', { headers: headers });
            }),
            map(res => res.result)
        );
    }
}
