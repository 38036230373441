import { Component, OnInit } from '@angular/core';

import { environment } from '../environments/environment';
import { AuthService } from './core/authentication/authentication.service';

@Component({
    selector: 'ap-root',
    templateUrl: './admin.portal.template.html'
})
export class AdminPortalComponent implements OnInit {
    version = environment.VERSION;
    showNavbar: boolean;

    constructor(
        private authService: AuthService
    ) {}

    ngOnInit() {
        this.authService.activeSession.subscribe(active => {
            this.showNavbar = active;
        });
    }
}
