import { NgModule } from '@angular/core';
import { UIRouterModule } from '@uirouter/angular';

import authConfig from './authentication.config';
import { AuthService } from './authentication.service';

import { SsoComponent } from './sso/sso.component';
import { UnauthenticatedComponent } from './unauthenticated/unauthenticated.component';
import { UnauthorisedComponent } from './unauthorised/unauthorised.component';

@NgModule({
    imports: [
        UIRouterModule.forChild(authConfig),
    ],
    providers: [
        AuthService
    ],
    declarations: [
        SsoComponent,
        UnauthenticatedComponent,
        UnauthorisedComponent
    ]
})

export class Auth {}
