import { PpfComponent } from './ppf/ppfAccounting.component';

const ppfComponent = {
    name: 'ppfAccounting',
    url: '/accounting-ppf',
    component: PpfComponent
};

export default {
    states: [
        ppfComponent
    ]
};
