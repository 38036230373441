import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, map, concatMap } from 'rxjs/operators';

import { prependRmf } from '../../admin.portal.http.config';
import { AuthService } from '../../core/authentication/authentication.service';

@Injectable()
export class DronesService {
    constructor(private http: HttpClient, private authService: AuthService) {}

    private httpHeaders() {
        return this.authService.getAuthHeader()
        .pipe(
            map(authHeader => {
                return new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': authHeader
                });
            })
        );
    }

    private errorHandler<T>(operation, result?: T) {
        return (err: any): Observable<T> => {
            return throwError({
                message: err.error.message
            });
        };
    }

    addDroneTemplate(template) {
        return this.httpHeaders()
        .pipe(
            concatMap(headers => {
                return this.http.post(prependRmf('/v1/uavTemplates'), template, { headers: headers });
            }),
            catchError(this.errorHandler('addDroneTemplate'))
        );
    }
}
