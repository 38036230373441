import { Injector, Component } from '@angular/core';
import { UIRouter } from '@uirouter/angular';

import { AuthService } from './core/authentication/authentication.service';

@Component({
    template: '<h3>Nowhere</h3>'
})
export class NowhereComponent { }

const nowhere = {
    name: 'nowhere',
    url: '/nowhere',
    component: NowhereComponent
};

function config(uiRouter: UIRouter, injector: Injector) {
    const $transitions = uiRouter.transitionService;

    $transitions.onBefore({}, async transition => {
        const toStateName = transition.to().name;
        const authService: AuthService = transition.injector().get(AuthService);

        // If we're going to one of the unprotected states just ignore this
        const unprotectedStates = [
            'sso',
            'unauthorised',
            'unauthenticated',
            'nowhere'
        ];

        if (unprotectedStates.includes(toStateName)) {
            return true;
        }

        // Otherwise
        try {
            const active = await authService.checkSession().toPromise();

            if (!active) {
                return transition.router.stateService.target('unauthenticated');
            }
        }
        catch (err) {
            console.error(err);
        }
    });

    $transitions.onError({}, transition => {
        console.error(transition.error());
    });
}

export default {
    config: config,
    otherwise: '/nowhere',
    states: [
        nowhere
    ]
};
