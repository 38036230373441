import { Injectable } from '@angular/core';

import OktaAuth from '@okta/okta-auth-js';
import { from, Observable, BehaviorSubject, of } from 'rxjs';
import { map, catchError, concatMap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
const oktaOrg = environment.oktaOrg;
const authApi = oktaOrg.api;
const clients = oktaOrg.clients;

const oktaConfig = {
    url: authApi.orgUrl,
    issuer: authApi.orgUrl + authApi.authServer,
    clientId: clients.spa.id,
    tokenManager: {
      storage: 'sessionStorage'
    }
};
const accessKey = 'accessToken';
const idKey = 'idToken';

@Injectable()
export class AuthService {
    private authClient = new OktaAuth(oktaConfig);

    activeSession = new BehaviorSubject<boolean>(false);

    constructor() {}

    // NOTE: Wrap Okta SDK functions in another promise to ensure they get registered
    // in Angular's change detection
    /**
     * Check whether an active (Okta) session exists
     */
    private async _checkActiveSession() {
        try {
            const session = await this.authClient.session.get();

            return (session.status === 'ACTIVE');
        }
        catch (err) {
            throw err;
        }
    }

    private async _getOktaToken(key: string) {
        try {
            const token = await this.authClient.tokenManager.get(key);
            return token;
        }
        catch (err) {
            console.error(err);
        }
    }

    private async _getTokens() {
        try {
            return await this.authClient.token.getWithoutPrompt({
                responseType: ['token', 'id_token'],
                clientId: oktaConfig.clientId
            });
        }
        catch (err) {
            throw err;
        }
    }

    private _storeTokens(key: string, token: any) {
        this.authClient.tokenManager.add(key, token);
    }

    private async _signOut() {
        try {
            await this.authClient.signOut();
        }
        catch (err) {
            console.error(err);
        }
    }

    /**
     * Check whether a (AP) session already exists
     */
    checkSession(): Observable<boolean> {
        const getTokensObs = from(this._getOktaToken(idKey))
        .pipe(
            catchError(err => {
                console.error(err);
                return of(null);
            }),
            map(id => {
                if (id && id.clientId === oktaConfig.clientId) {
                    this.activeSession.next(true);
                    return true;
                }

                this.activeSession.next(false);
                return false;
            })
        );

        return from(this._checkActiveSession())
        .pipe(
            concatMap(active => {
                return active ? getTokensObs : of(false);
            })
        );
    }

    /**
     * Tries to fetch fresh Okta tokens
     *
     * Adds the tokens to session storage
     * @returns If successful, true otherwise false
     */
    getTokens(): Observable<boolean> {
        return from(this._getTokens())
        .pipe(
            catchError(err => {
                // Suppress the error, just treat it as unauthorised
                console.log(err);
                return of(null);
            }),
            map(tokens => {
                if (tokens) {
                    this._storeTokens(accessKey, tokens[0]);
                    this._storeTokens(idKey, tokens[1]);

                    return true;
                }

                return false;
            })
        );
    }

    getAuthHeader(): Observable<string> {
        return from(this._getOktaToken(accessKey))
        .pipe(
            catchError(err => {
                return of(null);
            }),
            map(token => {
                if (token) {
                    return 'Bearer ' + (<any>token).accessToken;
                }
                else {
                    return null;
                }
            })
        );
    }

    destroySession() {
        return from(this._signOut())
        .pipe(
            map(() => {
                this.authClient.tokenManager.clear();
                this.activeSession.next(false);
            })
        );
    }
}
