import { Component, OnInit } from '@angular/core';

import { DronesService } from '../drones.service';

@Component({
    selector: 'ap-add-drone-template',
    templateUrl: './add-drone-template.template.html'
})
export class AddDroneTemplateComponent implements OnInit {
    droneTemplate: any = {};

    constructor(private drones: DronesService) {}

    ngOnInit() {

    }

    addDroneTemplate() {
        this.drones.addDroneTemplate(this.droneTemplate)
        .subscribe(
            result => {
                console.log(result);
                this.droneTemplate = {};
            },
            err => console.error(err)
        );
    }
}
