import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { UIRouterModule } from '@uirouter/angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import adminPortalConfig, { NowhereComponent } from './admin.portal.config';
import { AdminPortalComponent } from './admin.portal.component';

import { Accounting } from './accounting/accounting.module';
import { Assets } from './assets/assets.module';
import { Core } from './core/core.module';
import { Dashboard } from './dashboard/dashboard.module';

@NgModule({
    imports: [
        // Core deps
        BrowserModule,
        HttpClientModule,
        UIRouterModule.forRoot(adminPortalConfig),

        // Vendor deps
        NgbModule,

        // App deps
        Accounting,
        Assets,
        Core,
        Dashboard
    ],
    declarations: [
        AdminPortalComponent,
        NowhereComponent
    ],
    providers: [],
    bootstrap: [AdminPortalComponent]
})
export class AdminPortal {}
