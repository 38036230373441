import { Component, OnInit } from '@angular/core';

import { AccountingService } from '../accounting.service';

@Component({
    selector: 'ap-ppf-accounting',
    template: './ppf-accounting.template.html'
})
export class PpfComponent implements OnInit {
    title = 'Pay Per Fly Accounting';
    flights: any[];
    constructor (private accounting: AccountingService) {}

   ngOnInit () {
       this.accounting.ppfFlights()
       .subscribe(
           flights => {
               this.flights = flights;
            },
           error => console.error(error)
       );
    }
}
