import { NgModule } from '@angular/core';
import { UIRouterModule } from '@uirouter/angular';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

import { NavBarComponent } from './navBar.component';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [
        CommonModule,
        NgbDropdownModule,
        UIRouterModule
    ],
    declarations: [
        NavBarComponent,
    ],
    exports: [
        NavBarComponent
    ]
})
export class NavBar {}
