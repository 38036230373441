import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UIRouterModule } from '@uirouter/angular';

import dronesConfig from './drones.config';
import { AddDroneTemplateComponent } from './add-drone-template/add-drone-template.component';
import { DronesPageComponent } from './drones-page/drones-page.component';
import { DronesService } from './drones.service';

@NgModule({
    imports: [
        FormsModule,
        UIRouterModule.forChild(dronesConfig)
    ],
    declarations: [
        AddDroneTemplateComponent,
        DronesPageComponent
    ],
    providers: [
        DronesService
    ]
})
export class Drones {}
