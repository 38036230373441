import info from '../../package.json';

import { org } from '@pa/types-auth';

export const environment = {
    production: true,

    VERSION: info.version,

    loginRedirectUri: 'https://login.padev.studio',

    apiBaseUrl: {
        rmf: 'https://rmf.precision-autonomy.com/dev',
        cover: 'https://cover.precision-autonomy.com/dev',
        pay: 'https://pay.precision-autonomy.com/dev'
    },

    oktaOrg: org.dev
};
