import { Component } from '@angular/core';

import { environment } from '../../../environments/environment';
import { AuthService } from '../authentication/authentication.service';

@Component({
    selector: 'ap-nav-bar',
    templateUrl: './navBar.template.html'
})

export class NavBarComponent {
    constructor(
        private authService: AuthService
    ) {}

    logout() {
        this.authService.destroySession()
        .subscribe(() => window.location.href = environment.loginRedirectUri);
    }
}
