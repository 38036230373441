import { Component } from '@angular/core';

import { environment } from '../../../../environments/environment';

@Component({
    selector: 'ap-unauthenticated',
    templateUrl: './unauthenticated.template.html'
})
export class UnauthenticatedComponent {

    go() {
        window.location.href = environment.loginRedirectUri;
    }
}
